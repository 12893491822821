body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Lato;
  src: url(/static/media/Lato-Black.77d35374.ttf) format("truetype");
  src: url(/static/media/Lato-BlackItalic.6c522f09.ttf) format("truetype");
  src: url(/static/media/Lato-Bold.44dfe8cc.ttf) format("truetype");
  src: url(/static/media/Lato-BoldItalic.1ba4767e.ttf) format("truetype");
  src: url(/static/media/Lato-Hairline.db15ac79.ttf) format("truetype");
  src: url(/static/media/Lato-HairlineItalic.a567f4a8.ttf) format("truetype");
  src: url(/static/media/Lato-Italic.56c4cb26.ttf) format("truetype");
  src: url(/static/media/Lato-Light.5b761f2d.ttf) format("truetype");
  src: url(/static/media/Lato-LightItalic.3d747d8b.ttf) format("truetype");
  src: url(/static/media/Lato-Regular.7f690e50.ttf) format("truetype");
}
@font-face {
  font-family: Respective;
  src: url(/static/media/Respective.fe1a2d59.ttf) format("truetype");
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  font-family: Lato;
  font-weight:400;
  color: #2E3E48;
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: Lato;
}
.logo {
  font-family: Respective;
  font-size: 60px;
  color: #2E3E48;
  text-align: right;
  padding:0;
  padding-left:0px;
  white-space: nowrap;
}

textarea.logo {
  font-family: Respective;
  font-size: 60px;
  color: #2E3E48;
  text-align: right;
  padding-top: 20px;
  padding-left: 10px;
  border-width: 0px;
  width: 5cm;
  height:2cm;
  resize: none;
}

tr:first-child {
  width: 1%;
  white-space: nowrap;
}
.header1 {
  font-size:16px;
  font-weight:bold;
  text-align:left;
  }
.label {
  font-size: 12px;
/*                width: 0.5%;*/
/*                white-space: nowrap;*/
  padding-left:5px;
  padding-right:5px;
  }

.value {
  font-size: 12px;
  border-bottom: 1px solid;
  border-bottom-color: #2E3E48;
  padding-left:5px;
  padding-right:5px;
  white-space: nowrap;
  }
table  {
  width:100%;
  table-layout:fixed;
  height: auto;
/*                border: 1px dotted;*/
  border-spacing: 3px;
  white-space: normal;
}
td {
/*                background: rgb(100,10,1,0.1);*/
}
td, tr   {
/*                border: 1px dotted;*/
}
.blank-row   {
  font-size:8px;
}
.split-title   {
  font-size:14px;
  font-weight: bold;
}
.central-title  {
  font-size:14px;
  font-weight: bold;
  text-align: center;
}
.definitions-list   {
  list-style-type: square;
  font-size:12px;
}
.tsandcs   {
  font-size: 12px;
  white-space: normal;
}
.declaration {
  font-size: 12px;
  white-space: normal;
}
.input-box  {
  border-width: 0px;
  font-size: 12px;
  font-family: Lato;
  font-weight:400;
  color: #2E3E48;
}


/* https://stackoverflow.com/questions/20446252/stretch-div-to-a4-size */
.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #2E3E48;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0, 0.1);
}
.intro, .signInDiv {
  width: 21cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #2E3E48;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0, 0.1);
}

ul  {
  margin-top: 0px;
  margin-bottom: 0px;
}

@page {
  size: A4;
  margin: 0.75cm;
}
@media print {
      .page {
      margin: 0cm;
      border: initial;
      border-radius: initial;
/*                    width: initial;*/
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
  }
}

/* https://stackoverflow.com/questions/4098195/can-ordered-list-produce-result-that-looks-like-1-1-1-2-1-3-instead-of-just-1 */
ol {
list-style-type: none;
counter-reset: item;
margin: 0;
padding: 0;
}

ol > li {
display: table;
counter-increment: item;
margin-bottom: 0.6em;
}

ol > li:before {
content: counters(item, ".") ". ";
display: table-cell;
padding-right: 0.6em;    
}

li ol > li {
margin: 0;
}

li ol > li:before {
content: counters(item, ".") " ";
}

